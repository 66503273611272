import React, { useState } from "react";
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import "../assets/styles/LogIn.css"; // Import the external CSS file
import { IoIosDoneAll } from "react-icons/io";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";



const LogIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Basic validation

    if (username === "" && password === "") {
      setError("Username and password are required.");
      return;
    }

    if (username === "") {
      setError("Username required.");
      return;
    }

    if (password === "") {
      setError("Password required.");
      return;
    }

    // Clear error if validation passes
    setError("");

    // Perform login logic here (e.g., API call)
    console.log("Logging in:", { username, password });
  };

  return (
        <>
        <Header />
            <div id="LogIn">
      <div className="banner">
        <h1>Sign In</h1>
        <p className="text-center mt-4">
          <Link to="/">Home</Link> / Sign In
        </p>
      </div>

      <div className="login-container">
        <form onSubmit={handleLogin} className="login-form">
          <h4 className="mb-2">Sign In</h4>
          <p className="my-3 insert">Insert your account information:</p>

          <div className="input-group">
          <div className="password-input-container">
              <label htmlFor="username"><FaUser /></label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </div>
          </div>

          <div className="input-group">
            <div className="password-input-container">
            <label htmlFor="password"><FaLock /></label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Password"
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit">
            <span class="text">LOGIN</span>
            <span>
              Done <IoIosDoneAll />
            </span>
          </button>

          <p className="link">
            Forgot your <Link to="/">Password ?</Link>
          </p>
          <p className="link2">
            If you don't have an account, please <Link to="/register">Register Here</Link>
          </p>
        </form>
      </div>
    </div>
        <Footer />
        </>
  );
};

export default LogIn;
