import { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import { IoBagOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { FaBarsStaggered } from "react-icons/fa6";
import { HiBars3CenterLeft } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { FaArrowUp } from 'react-icons/fa'; // Import the arrow up icon from React Icons








const Navbar = () => {
  const [search, setSearch] = useState(false);
  const openSearchBar = () => {
    setSearch(true);
  };
  const closeSearchBar = () => {
    setSearch(false);
  };

  const [toggle, setToggle] = useState(false);
  const toggled = () => {
    setToggle((prev) => !prev);
  };

  const [isNavSideVisible, setIsNavSideVisible] = useState(false);
  const toggleNavSide = () => {
    setIsNavSideVisible((prev) => !prev);
  };


  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


 
  







  


  return (
    <>
      <div id="wrapper"  className={isFixed ? 'fixed' : ''}>
        <div className="Logo">
          <Link to="/">
            <h3>KARL</h3>
          </Link>
        </div>
        <div className={`main_menu ${toggle ? "main_menu--open" : ""}`}>
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/shop">SHOP</Link>
            </li>
            <li>
              <Link to="/blog">BLOG</Link>
            </li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
          </ul>
        </div>
        <div className="Icons">
          <div className="btns Search" >
            <span className="search-box">
              <CiSearch onClick={openSearchBar} />
              <IoClose
                className={search ? "show_btn" : "hide_btn"}
                onClick={closeSearchBar}
              />
            </span>
          </div>
          <div className="btns WishList" id="noneLink">
            <Link to="/wish-list">
              <CiHeart />
            </Link>
          </div>
          <div className="btns Cart" id="noneLink">
            <IoBagOutline />
            <span>0</span>
          </div>
          
        <div className="btns Bars" id="noneLink"  onClick={toggleNavSide}>
        {isNavSideVisible ? <IoClose size={24} /> : <FaBarsStaggered size={24} />}
        </div>
        <div
        className="NavSide"
        style={{ display: isNavSideVisible ? "flex" : "none" }} 
        >
        <ul>
          <li><Link to="/user">Log In</Link></li>
          <li><Link to="/user">Register</Link></li>
          <li><Link to="/check-out">Check out</Link></li>
          <li><Link to="/about">Wishlist</Link></li>
        </ul>
      </div>

          <div className="btns Bars-Menu">
            <HiBars3CenterLeft className="toggle_btn" onClick={toggled} />
          </div>
        </div>
        <div className={search ? "search_show" : "search_hide"}>
          <input placeholder="Search here..." />
        </div>










      </div>
    </>
  );
};

export default Navbar;
