import React from 'react';
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
const NavOffer = () => {
    return (
        <>
        <div className="NavOffer">
        <div className="col-md-3 col-lg-3 col-sm-3">
            <a href="#" className='ms-3'>
            <FaFacebookF />
            </a>
            <a href="#" className='mx-2'>
            <FaTwitter />
            </a>
            <a href="#" className='mx-2'>
            <FaInstagram />
            </a>
        </div>
        <div className=" text-center txt col-lg-6 col-md-6 col-sm-9">
            50% OFF all skincare this weekend - Enter code NOVACREATIVE
            </div>
            <div className="col-md-3"></div>
        </div>
              </>
    );
}

export default NavOffer;
