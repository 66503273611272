
import React, { useState } from 'react';
import './ProductList.css';
import { Rating } from 'react-simple-star-rating';
import { FaEye , FaRegHeart  } from 'react-icons/fa';
import { IoBagOutline } from "react-icons/io5";

const ProductList = ({ products }) => {
  const [rating, setRating] = useState(0);


  // Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  const onPointerEnter = () => console.log('Enter');
  const onPointerLeave = () => console.log('Leave');
  const onPointerMove = (value, index) => console.log(value, index);

  return (
    <div className="product-list">

      {products.map((product, index) => (
        <div key={index} className="product-item">
          <div className="overlay">
            <a href="/" className="product-thumb">
              <img src={product.image} alt={product.name} />
            </a>
          </div>

          <div className="product-info">
            <a href="/">{product.name}</a>
            <h4>{product.price}</h4>
            <Rating
            onClick={handleRating}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onPointerMove={onPointerMove}
          />
          </div>
          <ul className="icons">
            <li>
              <a href="/">
                <FaEye />
              </a>
            </li>
            <li>
              <a href="/">
                <IoBagOutline />
              </a>
            </li>
            <li>
              <a href="/">
                <FaRegHeart  />
              </a>
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
