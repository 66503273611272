import React from 'react';
import Logo from '../assets/images/logo-footer.png';
import payment from '../assets/images/logo-payment.webp';
import { Link } from 'react-router-dom';



const Footer = () => {
    return (
      <footer id="footer">
      <div className="container">
        <div className="row mb-3" id="row1">
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="logo mt-5 mb-3">
              <img src={Logo} alt="Logo-Brand" />
            </div>
            <div className="contact ">
              <p>2593 Timbercrest Road, Chisana, Alaska Badalas United State</p>
              <p>(+91)7-723-4608</p>
              <p>2593 Timbercrest Road, Chisana, Alaska Badalas United State</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6" id="company">
            <h4 className="mt-5 mb-3 ms-4 text-uppercase">company</h4>
            <ul>
              <li>
                <Link to="/about"> About us </Link>
              </li>
              <li>
                <Link to="/contact"> Contact </Link>
              </li>
              <li>
                <Link to="">Store Locations</Link>
              </li>
              <li>
                <Link to="">Careers</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6" id="help">
            <h4 className="mt-5 mb-3 ms-4 text-uppercase">help</h4>
            <ul>
              <li>
                <Link to="/">Order Tracking</Link>
              </li>
              <li>
                <Link to="/faqs">FAQ’s</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-services">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2  col-sm-6 col-md-6" id="store">
            <h4 className="mt-5 mb-3 ms-4 text-uppercase">store</h4>
            <ul>
              <li>
                <Link to="/">new</Link>
              </li>
              <li>
                <Link to="/">skincare</Link>
              </li>
              <li>
                <Link to="/">makeup</Link>
              </li>
              <li>
                <Link to="/">sale</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="row" id="row2">
          <div className="col-lg-4" id="help">
            <ul>
              <li>
                <Link href="">Privacy Policy </Link>
              </li>
              <li>
                <Link href="">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 text-center" id="payment">
            <img src={payment} alt="Logo-Payment" />
          </div>
          <div className="col-lg-3 text-end" id="copyright">
            <p>©2022 NovaThemes</p>
          </div>
        </div> */}
      </div>
    </footer>
    );
}

export default Footer;
