import React from 'react';
import Navbarr from './Navbar';
import NavOffer from './NavOffer';

const Header = () => {
    return (
        <div>
            <NavOffer/>
            <Navbarr/>
        </div>
    );
}

export default Header;
