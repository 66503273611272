import React from 'react';

const WishList = () => {
    return (
        <div>
            WishList
        </div>
    );
}

export default WishList;
