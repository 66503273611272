import React, { useState } from "react";
import '../assets/styles/Faqs.css'

const paragraph = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet natus sint provident vel ab reprehenderit cum soluta, suscipit facere nisi sed earum repellendus fuga debitis, nam molestiae minima voluptates possimus.";
const data = [
  {
    title: "Sed est elit posuere ac semper at hendrerit a neque?",
    paragraph,
  },
  {
    title: "Why Lorem ipsum dolor sit amet ?",
    paragraph,
  },
  {
    title: "What maecenas commodo mi urna, quis accumsan ligula gravida at ?",
    paragraph,
  },
  {
    title:
      "Integer venenatis suscipit urna id imperdiet. In et mi quis sapien tristique sodales sit amet nec justo ?",
    paragraph,
  },
  {
    title: "Donec lobortis dolor a arcu auctor tincidunt ac fermentum enim ?",
    paragraph,
  },
  {
    title: "Maecenas vitae placerat metus ?",
    paragraph,
  },
  {
    title: "Vestibulum nulla velit, tincidunt id neque sed ?",
    paragraph,
  },
  {
    title:"Sed est elit posuere ac semper at hendrerit a neque?",
    paragraph,
  },
];

const FAQs = () => {
  return (
    <>
      <div id="FAQs">
        <div className="banner">
          <h1>Page FAQS</h1>
          <p className="text-center mt-4">
            <a href="">Home</a> / FAQs
          </p>
        </div>
        <div className="wrapper container">
          <div className="text">
          <h2>Frequently asked question</h2>
          </div>
          <ul className="accordion-list">
            {data.map((data, key) => {
              return (
                <li className="accordion-list__item" key={key}>
                  <AccordionItem {...data} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

const AccordionItem = ({ title, paragraph }) => {
  const [opened, setOpened] = useState(false);
  return (
    <div
      className={`accordion-item ${opened ? "accordion-item--opened" : ""}`}
      onClick={() => setOpened(!opened)}
    >
      <div className="accordion-item__line">
        <h3 className="accordion-item__title">{title}</h3>
        <span className="accordion-item__icon" />
      </div>
      <div className="accordion-item__inner">
        <div className="accordion-item__content">
          <p className="accordion-item__paragraph">{paragraph}</p>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
