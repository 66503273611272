import React from 'react';

const ShopCart = () => {
    return (
        <div>
            Shopping Cart
        </div>
    );
}

export default ShopCart;
