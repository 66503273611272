import React from 'react';
import '../assets/styles/Blog.css'
import { FaUser } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

import Blo1 from '../assets/images/blog-1.webp'
import Blo2 from '../assets/images/blog-2.webp'
import Blo3 from '../assets/images/blog-3.webp'
import { Button, Card } from 'react-bootstrap';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
const Img1 = Blo1;
const Img2 = Blo2;
const Img3 = Blo3;
const data = [
    {
        Img:Img1,
        key: 1,
        title:'How to: Luxe New Year',
        Description:'Valentine’s Day 2021 has no choice but to unfold rather differently than usual, so we already know it will be one to remember. Lorem ipsum dolor sit amet, consectetur adipisicin...',
        Date:'October 15 2022',
    },
    {
        Img:Img2,
        key: 2,
        title:'How to: Luxe New Year',
        Description:'Valentine’s Day 2021 has no choice but to unfold rather differently than usual, so we already know it will be one to remember. Lorem ipsum dolor sit amet, consectetur adipisicin...',
        Date:' Novamber 15 2022',
    },
    {
        Img:Img3,
        key: 3,
        title:'How to: Luxe New Year',
        Description:'Valentine’s Day 2021 has no choice but to unfold rather differently than usual, so we already know it will be one to remember. Lorem ipsum dolor sit amet, consectetur adipisicin...',
        Date:'December 15 2022',
    },
]

const Blog = () => {
    return (
        <>
            <Header/>
            <div id='Blog'>
            <div className="banner">
          <h1>News</h1>
          <p className="text-center mt-4">
            <Link to="/">Home</Link> / News
          </p>
        </div>

<div className='content'>
{data.map((data, key) => {
return (
<Link to="/blog-details">
<Card key={key} style={{width:'98%' , margin:'auto' , minHeight:'85vh'}} className='border-0'>
<Card.Img  src={data.Img} className='IMG'  />
<Card.Body>
    <Card.Title>
        <span className='h4'>{data.title}</span>
    </Card.Title>
    <Card.Text>
    <div className="Icons">
        <FaCalendarAlt  className='gray ms-3' />
        <p className='gray'>{data.Date}</p>
        |
        <FaUser />
        <p> Vinova Theme </p>
    </div>
    </Card.Text>
    <Card.Text>
    <p className='description'>{data.Description}</p>
    </Card.Text>
    <Button className='btn'><span></span>Read More</Button>
</Card.Body>
</Card>          
</Link>
);
})}
</div>
    </div>
            <Footer />
        </>
    );
}

export default Blog;

