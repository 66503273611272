import React, { useState } from "react";
import "../assets/styles/BlogDetails.css";
import { FaUser } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { Card } from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import Blo1 from "../assets/images/blog-1.webp";
import Blo2 from "../assets/images/blog-2.webp";
import Blo3 from "../assets/images/blog-3.webp";

const Img1 = Blo1;
const Img2 = Blo2;
const Img3 = Blo3;
const data = [
  {
    Img: Img1,
    key: 1,
    title: "How to: Luxe New Year",
    Description:
      "Valentine’s Day 2021 has no choice but to unfold rather differently than usual, so we already know it will be one to remember. Lorem ipsum dolor sit amet, consectetur adipisicin...",
    Date: "October 15 2022",
  },
  {
    Img: Img2,
    key: 2,
    title: "How to: Luxe New Year",
    Description:
      "Valentine’s Day 2021 has no choice but to unfold rather differently than usual, so we already know it will be one to remember. Lorem ipsum dolor sit amet, consectetur adipisicin...",
    Date: " Novamber 15 2022",
  },
  {
    Img: Img3,
    key: 3,
    title: "How to: Luxe New Year",
    Description:
      "Valentine’s Day 2021 has no choice but to unfold rather differently than usual, so we already know it will be one to remember. Lorem ipsum dolor sit amet, consectetur adipisicin...",
    Date: "December 15 2022",
  },
];

const BlogDetails = () => {
  return (
    <>
      <div id="BlogDetails">
        <div className="banner">
          <h1>How to: Party Makup</h1>
          <p className="text-center mt-4">
            <a href="/">Home</a> / <a href="/blog">News</a> / How to: Party
            Makup
          </p>
        </div>
        <div className="content">
          <div className="img-blog">
            <img src={Img1} alt="Blog-image-makeup" />
          </div>
          <div className="content-text">
            <h2>How to: Party Makup</h2>
            <p>
              Valentine’s Day 2021 has no choice but to unfold rather
              differently than usual, so we already know it will be one to
              remember.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim adminim veniam libero lobortis in dictum velit luctus. Donec
              imperdiet tincidunt interdum.
            </p>

            <h3>Here, we bring you a look-see:</h3>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim adminim veniam pulvinar dui viverra libero lobortis in dictum
              velit luctus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              ut iaculis arcu. Proin tincidunt, ipsum nec vehicula euismod,
              neque nibh pretium lorem, at ornare risus sem et risus. Curabitur
              pulvinar dui viverra libero lobortis in dictum velit luctus. Donec
              imperdiet tincidunt interdum
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim adminim veniam. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur ut iaculis arcu. Proin tincidunt, ipsum
              nec vehicula euismod, neque nibh pretium lorem, at ornare risus
              sem et risus. Curabitur pulvinar dui viverra libero lobortis in
              dictum velit luctus. Donec imperdiet tincidunt interdum
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipisicing elit, do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim
              adminim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Curabitur ut iaculis arcu. Proin tincidunt, ipsum nec
              vehicula euismod neque
            </p>

            <div className="icons">
              <FaCalendarAlt className="gray ms-1" />
              <p className=" gray mx-2">October 15 2022 </p>
              |
              <FaUser className="mx-2" />
              <p> Vinova Theme </p>
            </div>
          </div>
        </div>

        <div className="relatedNews">
          <h1 className="my-5 ms-3">Related News</h1>
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            navigation={{ clickable: true }}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
          >
            {data.map((item) => (
              <SwiperSlide key={item.key}>
                <Card className="border-0">
                  <Card.Img src={item.Img} className="IMG" />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.Description}</Card.Text>
                  </Card.Body>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
