import React, { useState } from "react";
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import "../assets/styles/SignUp.css"; // Import the external CSS file
import { IoIosDoneAll, IoMdMail } from "react-icons/io";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import { Link } from "react-router-dom";







const SignUp = () => {
  
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Basic validation

    if (firstname === "" && password === "" && lastname === "" && mail === "") {
      setError("Username , password and Mail are required.");
      return;
    }

    if (firstname === "") {
      setError("First name required.");
      return;
    }

    if (lastname === "") {
      setError("Last name required.");
      return;
    }

    if (mail === "") {
      setError("Mail required.");
      return;
    }

    if (password === "") {
      setError("Password required.");
      return;
    }

    // Clear error if validation passes
    setError("");

    // Perform login logic here (e.g., API call)
    console.log("Logging in:", { firstname, lastname, mail, password });
  };

  return (
    <>
    <Header />
        <div id="SignUp">
      <div className="banner">
        <h1>Create an account</h1>
        <p className="text-center mt-4">
          <Link to="/">Home</Link> / Create an account
        </p>
      </div>

      <div className="login-container">
        <form onSubmit={handleLogin} className="login-form">
          <p className="my-3 insert">Create an account</p>

          <div className="input-group">
            <div className="password-input-container">
              <label htmlFor="firstname">
                <FaUser />
              </label>
              <input
                type="text"
                id="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="First name"
              />
            </div>
          </div>

          <div className="input-group">
            <div className="password-input-container">
              <label htmlFor="lastname">
                <FaUser />
              </label>
              <input
                type="text"
                id="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                placeholder="Last name"
              />
            </div>
          </div>




          <div className="input-group">
            <div className="password-input-container">
              <label htmlFor="mail">
                <IoMdMail />
              </label>
              <input
                type="email"
                id="mail"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                placeholder="Email  xxv@domin.exmple"
              />
            </div>
          </div>

          <div className="input-group">
            <div className="password-input-container">
              <label htmlFor="password1">
                <FaLock />
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password1"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Password"
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          {error && <p className="error">{error}</p>}

          <button type="submit">
            <span class="text">Sign up</span>
            <span>
              Done <IoIosDoneAll />{" "}
            </span>
          </button>

          <div className="check my-3">
            <label class="custom-checkbox">
              <input name="dummy" type="checkbox" />
              <span class="checkmark"></span>
              Sign up for our newsletter
            </label>
          </div>

          <p className="link2">
            If you have an account, please <Link to="/login">Login Here</Link>
          </p>
        </form>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default SignUp;
