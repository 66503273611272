import React from 'react';
import '../assets/styles/ContactUS.css' 
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

const ContactUS = () => {
    return (
    <div id='ContactUS'>
<div className="banner">
<h1>Page Contact Us</h1>
<p className="text-center mt-4">
<a href="/">Home</a> / Page Contact Us </p> 
    </div>
            
<div className="content my-5">
    <div className="container">
        <div className="content-text">
        <div className='info-text'>
            <p>
                <strong>Call Customer Services </strong>
                :<br/>84-0123-456-789
                </p>
                </div>
        <div className='info-text'>
            <p>
        <strong>Address</strong>:<br/>PO Box 1622 Vissaosang Street West
        </p>
        </div>
        <div className='info-text'> 
            <p> 
            <strong>Email</strong>:<br />Support@domain.com
             </p>
         </div>
</div>

<div className="map my-5">
      <iframe
        title="Map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5687319.630136097!2d30.827356!3d26.82376!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14368976c35c36e9%3A0x2c45a00925c4c444!2sEgypt!5e1!3m2!1sen!2sus!4v1724800970930!5m2!1sen!2sus%22%20width=%22600%22%20height=%22450%22%20style=%22border:0;%22%20allowfullscreen=%22%22%20loading=%22lazy%22%20referrerpolicy=%22no-referrer-when-downgrade"
        allowFullScreen
        loading="lazy"
      ></iframe>
</div>

<div className="contact-text row">
    <div className="Scoial-Links col-lg-6">
        <a href="/" className='mx-1 mt-2 mb-5'><FaFacebook/></a>
        <a href="/" className='mx-1 mt-2 mb-5'><FaTwitter/></a>
        <a href="/" className='mx-1 mt-2 mb-5'><FaInstagram/></a>
</div>
<div className="col-lg-6 contact-input">   
    <div className="form-wrapper">
        <div className="form-container">
          <h2 className="form-title">Contact Us</h2>
          <form>
            <div className="form-group">
              <label className="form-label" htmlFor="name">Your Name</label>
              <input
                className="form-input"
                placeholder="Enter your name"
                type="text"
                
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="email">Your Email</label>
              <input
                className="form-input"
                placeholder="Enter your email"
                name="email"
                id="email"
                type="email"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="message">Your Message</label>
              <textarea
                className="form-textarea"
                rows="4"
                placeholder="Enter your message"
                name="message"
                id="message"
              ></textarea>
            </div>
            <button
              className="form-button"
              type="submit"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>











</div>
</div>












    </div>
</div>











        </div>
    );
}

export default ContactUS;
