import React from "react";
import '../assets/styles/CheckOut.css';
import { FaSearch } from "react-icons/fa";
import { MdOutlineMoneyOffCsred } from "react-icons/md";
import Img from '../assets/images/Logo.webp'


const CheckOut = () => {
  return (
    <div id="checkout">
      <div className="checkForm ">
        <div id="form">
          <div className="container" id="block">
            <form action="">
              <div className="row">
                <div className="colo">
                  <div className="formContact">
                    <div className="title d-flex justify-content-between">
                      <h3 className="title">Contact</h3>
                      <a href="/"> Login</a>
                    </div>
                    <div className="inputBox">
                      <input type="email" placeholder="example@domin.com" />
                    </div>
                    <div className="checkLabel d-flex">
                      <input
                        type="checkbox"
                        name=""
                        id="1"
                        className="me-3"
                        required
                      />
                      <label htmlFor="1">Email me with news and offers</label>
                    </div>
                  </div>
                  <div className="formDelivery mt-4">
                    <h3 className="title">Delivery</h3>
                    <div className="inputBox-Delavary">
                      <select id="country" name="country">
                        <option value="Egypt">Egypt</option>
                        <option value="Us">Us</option>
                        <option value="Canada">Canada</option>
                      </select>
                    </div>
                    <div className="delavaryName d-flex mt-4 justify-content-between">
                      <input type="text" placeholder="First name" />
                      <input type="text" placeholder="Last name" />
                    </div>
                    <div className="inputBox searchInput">
                      <input
                        type="text"
                        placeholder="First name"
                        id="2"
                        className="searches"
                      />
                      <label htmlFor="2">
                        <FaSearch />
                      </label>
                    </div>
                    <div className="inputBox">
                      <input type="text" placeholder="Apartment , etc" />
                    </div>
                    <div className="flex">
                      <div className="inputBox w-100">
                        <input type="text" placeholder="City" />
                      </div>
                      <div className="inputBox">
                        <input type="text" placeholder="state" />
                      </div>
                      <div className="inputBox">
                        <input type="text" placeholder="zip code" />
                      </div>
                    </div>
                    <div className="checkLabel d-flex mb-2">
                      <input
                        type="checkbox"
                        name=""
                        id="3"
                        className="me-3"
                        required
                      />
                      <label htmlFor="3">
                        Save this information for next time
                      </label>
                    </div>
                  </div>
                  <h5 className="mt-4">Shiping method</h5>
                  <div className="Shipping">
                    <p>
                      Enter your shipping address to view available shipping
                      methods.
                    </p>
                  </div>
                  <h5 className="mt-4">Payment</h5>
                  <p>All transactions are secure and encrypted.</p>
                  <div className="Payment mb-5">
                    <MdOutlineMoneyOffCsred />
                    <p>This store can’t accept payments right now.</p>
                  </div>
                </div>
              </div>
              <input
                type="submit"
                defaultValue="proceed to checkout"
                className="submit-btn"
              />
            </form>
            <div className="Subtotal ms-5 mt-0">
              <div className="container d-block">
                <div className="products w-100">
                  <div className="product d-flex align-items-center justify-content-between my-5">
                    <img src={Img} alt="" />
                    <p>Hydrating Facial Cleanser</p>
                    <p>$385.00 </p>
                  </div>
                  <div className="product d-flex align-items-center my-5 justify-content-between">
                    <img src={Img} alt="" />
                    <p>Hydrating Facial Cleanser</p>
                    <p>$385.00 </p>
                  </div>
                  <div className="product d-flex align-items-center my-5 justify-content-between">
                    <img src={Img} alt="" />
                    <p className="ms-5">Hydrating Facial Cleanser</p>
                    <p className="ms-5">$385.00 </p>
                  </div>
                  <div className="product d-flex align-items-center my-5 justify-content-between ">
                    <img src={Img} alt="" />
                    <p className="ms-5">Hydrating Facial Cleanser</p>
                    <p className="ms-5">$385.00 </p>
                  </div>
                  <div className="contentt">
                  <div className="Subtotal w-100 d-flex justify-content-between">
                    <p>Subtotal</p>
                    <p >$1,540.00</p>
                  </div>
                  <div
                    className="Ship d-flex justify-content-between
 d-flex"
                  >
                    <p>Shipping</p>
                    <p>Enter shipping address</p>
                  </div>
                  <div
                    className="total d-flex justify-content-between
 d-flex"
                  >
                    <h3>Total</h3>
                    <h3> $1,540.00</h3>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
