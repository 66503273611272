import React, { useState } from "react";
import "../assets/styles/About.css";
import ArtImg from "../assets/images/about-us.webp";
import { FaQuoteLeft } from "react-icons/fa";
import { Card } from "react-bootstrap";
import { FaPhoneVolume } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Img from "../assets/images/cart-img.jpg";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";

const Image = Img;
const data = [
  {
    Image,
    key: 1,
    name: "Kwang Sang",
    job: "CEO- Vinova",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium metus. Curabi tur velit sem, egestas sit amet dolor nec, tris tique vulputate enim.",
    phone: " (+612) 2531 5600",
    email: "sonata@domain.com",
  },
  {
    Image,
    key: 2,
    name: "Rebecca Jenkins",
    job: "Developer",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium metus. Curabi tur velit sem, egestas sit amet dolor nec, tris tique vulputate enim.",
    phone: " (+612) 2531 5600",
    email: "sonata@domain.com",
  },
  {
    Image,
    key: 3,
    name: "Alex Nguyen",
    job: "Designer",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium metus. Curabi tur velit sem, egestas sit amet dolor nec, tris tique vulputate enim.",
    phone: " (+612) 2531 5600",
    email: "sonata@domain.com",
  },
  {
    Image,
    key: 4,
    name: "Lucy Watson",
    job: "Developer",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac pretium metus. Curabi tur velit sem, egestas sit amet dolor nec, tris tique vulputate enim.",
    emaphoneil: " (+612) 2531 5600",
    email: "sonata@domain.com",
  },
];

const About = () => {
  return (
    <>
    <Header/>
    <div id="About">
        <div className="banner">
          <h1>Page About Us</h1>
          <p className="text-center mt-4">
            <a href="/">Home</a> / Page About Us
          </p>
        </div>
        <div className="content">
          <div className="container">
            <div className="content-txt">
              <article className="art-img">
                <img src={ArtImg} alt="banner-img" />
              </article>
              <article className="art-txt">
                <h3>Our company</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac
                  ligula et mi semper viverra nec a tellus. Curabitur sed diam
                  quis elit tempus venenatis sit amet a tellus. Sed id lectus
                  laoreet, elementum metus at, sagittis sem. Sed ante velit,
                  eleifend sit amet eros lacinia, lobortis vehicula dui. Vivamus
                  fringilla mauris mauris. Aliquam lectus ligula, porta a
                  rhoncus sit amet, consequat vel nibh. Sed in est venenatis,
                  dictum dui a, consequat quam.
                </p>
                <p>
                  Etiam consectetur accumsan odio sit amet sagittis. Aliquam
                  erat volutpat. Maecenas eros lacus, commodo id leo ut,
                  ullamcorper varius elit. Proin auctor quam convallis, iaculis
                  odio non, iaculis tortor. In felis tortor, tristique at magna
                  nec, pharetra posuere leo. Praesent ut felis ante.
                  Pellentesque rutrum sed turpis at ornare. Nulla tincidunt enim
                  vitae purus rhoncus pellentesque ut ac ligula. Vivamus vel
                  gravida nulla, quis aliquam est. Proin ultrices semper tortor,
                  at suscipit nibh imperdiet vel. Etiam quis velit viverra nisl
                  lobortis imperdiet quis mollis metus. Integer nec felis at
                  ligula finibus consequat at ut magna. Duis fermentum dignissim
                  metus in pellentesque. Integer viverra iaculis quam vel
                  convallis. Donec et leo non velit pharetra placerat. Donec
                  condimentum enim vitae felis vulputate, non rhoncus leo
                  fringilla.
                </p>

                <div className="Quote d-flex">
                  <div className="icon me-4">
                    <FaQuoteLeft />
                  </div>
                  <blockquote>
                    I am quote block text, consectetur adipiscing elit. Ut ac
                    ligula et mi semper viverra nec a tellus. Curabitur sed diam
                    quis elit tempus venenatis sit amet a tellus. Sed id lectus
                    laoreet, elementum metus at, sagittis sem. Sed ante velit,
                    eleifend sit amet eros lacinia, lobortis vehicula dui.
                    Vivamus fringilla mauris mauris. Aliquam lectus ligula porta
                    a rhoncus sit amet consequat vel nibh.
                  </blockquote>
                </div>

                <h3>Our Team</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac
                  ligula et mi semper viverra nec a tellus. Curabitur sed diam
                  quis elit tempus venenatis sit amet a tellus. Sed id lectus
                  laoreet, elementum metus at, sagittis sem. Sed ante velit,
                  eleifend sit amet eros lacinia, lobortis vehicula dui. Vivamus
                  fringilla mauris mauris. Aliquam lectus ligula, porta a
                  rhoncus sit amet, consequat vel nibh. Sed in est venenatis,
                  dictum dui a, consequat quam.
                </p>
                <p>
                  Etiam consectetur accumsan odio sit amet sagittis. Aliquam
                  erat volutpat. Maecenas eros lacus, commodo id leo ut,
                  ullamcorper varius elit. Proin auctor quam convallis, iaculis
                  odio non, iaculis tortor. In felis tortor, tristique at magna
                  nec, pharetra posuere leo. Praesent ut felis ante.
                  Pellentesque rutrum sed turpis at ornare. Nulla tincidunt enim
                  vitae purus rhoncus pellentesque ut ac ligula. Vivamus vel
                  gravida nulla, quis aliquam est. Proin ultrices semper tortor,
                  at suscipit nibh imperdiet vel. Etiam quis velit viverra nisl
                  lobortis imperdiet quis mollis metus. Integer nec felis at
                  ligula finibus consequat at ut magna. Duis fermentum dignissim
                  metus in pellentesque. Integer viverra iaculis quam vel
                  convallis. Donec et leo non velit pharetra placerat. Donec
                  condimentum enim vitae felis vulputate, non rhoncus leo
                  fringilla.
                </p>
              </article>
            </div>
          </div>
        </div>
        <div className="cardsTeam">
          <div className="row container m-auto justify-content-between">
            {data.map((data, key) => {
              return (
                <Card
                  style={{ width: "16rem" }}
                  className="col-lg-3 col-md-6 col-sm-12"
                  key={key}
                >
                  <div className="img">
                    <img src={data.Image} alt="" />
                  </div>
                  <Card.Body>
                    <Card.Title>{data.name}</Card.Title>
                    <Card.Text>
                      <p>{data.job}</p>
                    </Card.Text>
                    <Card.Text>{data.title}</Card.Text>
                    <Card.Text>
                      <div className="Scoial d-flex">
                        <p className="icon me-4">
                          <FaPhoneVolume />
                        </p>
                        <p>{data.phone}</p>
                      </div>
                      <div className="Scoial d-flex">
                        <p className="icon me-4">
                          <CiMail />
                        </p>
                        <p>{data.email}</p>
                      </div>
                    </Card.Text>
                    <Card.Text>
                      <div className="Scoial-Links">
                        <a href="/" className="social-Link mx-1">
                          <FaFacebook />
                        </a>
                        <a href="/" className="social-Link mx-1">
                          <FaTwitter />
                        </a>
                        <a href="/" className="social-Link mx-1">
                          <FaInstagram />
                        </a>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    <Footer/>
    </>
  );
};

export default About;
