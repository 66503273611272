import {createBrowserRouter} from "react-router-dom";
import Home from "../pages/Home";
import User from "../pages/User";
import FAQS from "../pages/FAQS";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsServices from "../pages/TermsServices";
import Shop from "../pages/Shop";
import WishList from "../pages/WishList";
import ShopCart from "../pages/ShopCart";
import About from "../pages/About";
import ContactUS from "../pages/ContactUS";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import CheckOut from "../pages/CheckOut";
import LogIn from "../pages/LogIn";
import SignUp from "../pages/SignUp";




const router = createBrowserRouter([
    {
      path: "/",
      element:<Home />,
    },
    {
      path: "/faqs",
      element: <FAQS/>,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy/>,
    },
    {
      path: "/terms-services",
      element: <TermsServices/>,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/shop",
      element: <Shop />,
    },
    {
      path: "/wish-list",
      element: <WishList />,
    },
    {
      path: "/shopping-cart",
      element: <ShopCart />,
    },
    {
      path: "/contact",
      element: <ContactUS />,
    },
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "/blog-details",
      element: <BlogDetails />,
    },
    {
      path: "/check-out",
      element: <CheckOut />,
    },
    {
      path: "/login",
      element: <LogIn/>,
    },
    {
      path: "/register",
      element: <SignUp/>,
    },









  ]);


  export default router;