import React from 'react';
import ProductAccordion from '../components/Shop/ProductAccordion';
import ProductList from '../components/Shop/ProductList';

import Shop from '../assets/images/Shop.webp';
import Shop2 from '../assets/images/shop2.webp';
import '../assets/styles/Shop.css'

const Img1 = Shop;
const Img2 = Shop2;
const Img3 = Shop;





const products = [
  { name: 'Hydrating Facial Cleanser', price: '£ 41.86', image: Img1 },
  { name: 'Pure Radiant Tinted Moisturiser', price: '£73.06', image: Img2 },
  { name: 'Hydrating Facial Cleanser', price: '£ 41.86', image: Img3 },
  { name: 'Pure Radiant Tinted Moisturiser', price: '£73.06', image: Img2 },

  { name: 'Hydrating Facial Cleanser', price: '£ 41.86', image: Img1 },
  { name: 'Pure Radiant Tinted Moisturiser', price: '£73.06', image: Img2 },
  { name: 'Hydrating Facial Cleanser', price: '£ 41.86', image: Img3 },
  { name: 'Pure Radiant Tinted Moisturiser', price: '£73.06', image: Img2 },

  { name: 'Hydrating Facial Cleanser', price: '£ 41.86', image: Img1 },
  { name: 'Pure Radiant Tinted Moisturiser', price: '£73.06', image: Img2 },
  { name: 'Hydrating Facial Cleanser', price: '£ 41.86', image: Img3 },
  { name: 'Pure Radiant Tinted Moisturiser', price: '£73.06', image: Img2 },


  

];












const Products = () => {
    return (
<div id='Shop'>

<div className="shop-page">
      <div className="shop-sidebar">
        <ProductAccordion />
      </div>
      



      <div className="shop-products">
        <ProductList products={products} />
      </div>
    </div>
























        </div>
    );
}

export default Products;
