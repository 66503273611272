import React, { useState } from 'react';
import { FaMinus, FaPlus , FaTrash } from "react-icons/fa";
import { VscSettings } from "react-icons/vsc";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import './ProductAccordion.css';

const ProductAccordion = () => {
  const [openSections, setOpenSections] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    minPrice: '',
    maxPrice: ''
  });

  const toggleAccordion = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleCheckboxChange = (section, option) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [option]: !prev[section]?.[option],
      },
    }));
  };


  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };






  const filters = [
    { title: "PRODUCT TYPE", content: ["All", "Eye Care", "Face", "Lavender", "Lips", "Makeup", "Mint Oil", "T-Shirt"] },
    { title: "BRAND", content: ["Burberry", "Karl", "Kenzo", "Louis Vuitton", "Police", "Tomford", "Vinova"] },
    { title: "SIZE", content: ["50ml", "100ml", "150ml", "200ml" , "250ml" , "300ml"] },
    { title: "COLOR", content: ["Red", "Blue", "Green", "Black", "Brown", "Yellow", "Pink"] },
    
    { title: "TAGS", content: ["$10 - $100", "$101 - $200", "Black", "Blue", "50ml", "100ml", "200ml"] },

    // Add more filters as needed
  ];









  return (
    <div className="accordion">
    <div className="head d-flex gap-3 mb-1 ">
        <VscSettings className='display-6'/>
      <h3> FILTER BY </h3>
    </div>
      <hr  className='mb-2' />


    <div className="clear">
      <a to="" className='text-decoration-none'><FaTrash/> <h4>  CLEAR ALL </h4></a>
    </div>



      {/* Price Filter Section */}
      <div className="accordion-section">
        <div className="accordion-header" onClick={() => toggleAccordion('Price')}>
          <h5><HiOutlineBars3BottomLeft /> PRICE</h5>
          {openSections['Price'] ? <FaMinus /> : <FaPlus />}
        </div>
        {openSections['Price'] && (
          <div className="accordion-content">
            <div className="price-inputs">
              <label>
                <input
                  type="number"
                  name="minPrice"
                  value={selectedFilters.minPrice}
                  onChange={handlePriceChange}
                  placeholder="0"
                />
                <span className='ms-2 display-6'>-</span>
                <input
                  type="number"
                  name="maxPrice"
                  value={selectedFilters.maxPrice}
                  onChange={handlePriceChange}
                  placeholder="156.00."
                  className='ms-1'
                />
              </label>
            </div>
          </div>
        )}
      </div>

      {filters.map((filter, index) => (
        <div key={index} className="accordion-section">
          <div className="accordion-header" onClick={() => toggleAccordion(filter.title)}>
            <h5><HiOutlineBars3BottomLeft /> {filter.title}</h5>
            {openSections[filter.title] ? <FaMinus /> : <FaPlus />}
          </div>
          {openSections[filter.title] && (
            <div className="accordion-content">
              <ul>
                {filter.content.map((item, idx) => (
                  <li key={idx}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedFilters[filter.title]?.[item] || false}
                        onChange={() => handleCheckboxChange(filter.title, item)}
                      />
                      {item}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}




     





    </div>
  );
};

export default ProductAccordion;