import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
// import PrivacyPolicy from './PrivacyPolicy';
// import TermsServices from './TermsServices';
// import FAQs from './FAQS';
// import ContactUS from './ContactUS';
import CheckOut from './CheckOut';
import Blog from './Blog';
import BlogDetails from './BlogDetails';
import SignUp from './SignUp';
import LogIn from './LogIn';
// import Footer from '../components/Footer';



const Home = () => {
    return (
        <>
        <Header />
            {/* <LogIn />.
            
            */}
        {/* <Footer /> */}
        </>
    );
}

export default Home;
